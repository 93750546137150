

.place-bid-button {
  border-width: 0.44px;
  border-style: solid;
  background-image: linear-gradient(
    90deg,
    rgb(82, 204, 213) 0%,
    rgb(115, 230, 238) 100%
  );
  box-shadow: 0.105px 5.999px 21px 0px rgba(83, 205, 214, 0.42);
}

.cancel-bid-button {
  border-width: 0.44px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  background-image: linear-gradient(
    90deg,
    rgb(82, 204, 213) 0%,
    rgb(115, 230, 238) 100%
  );
  box-shadow: 0.105px 5.999px 21px 0px rgba(83, 205, 214, 0.42);
  opacity: 0.9;
}

.place-bid-button:hover {
  text-decoration: none;
}

.cancel-bid-button:hover {
  border-width: 0.44px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  background-image: linear-gradient(
    90deg,
    rgb(82, 204, 213) 0%,
    rgb(115, 230, 238) 100%
  );
  box-shadow: 0.105px 5.999px 21px 0px rgba(83, 205, 214, 0.42);
  opacity: 0.5;
}

.topBidsButton {
  background-color: #ffe7ee;
  border-radius: 40px;
  border-color: #f0f2f7;
  border-width: 0.44px;


  z-index: 100;
  position: absolute;
  bottom: 40%;
  text-align: center;
  justify-content: center;
}

.timeColor {
  color: #02b1b4;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
